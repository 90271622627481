import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { Button } from '../components/Button';
import { FormInput } from '../components/FormInput';
import { FormPhoneSelect } from '../components/FormPhoneSelect';
import { FormTextArea } from '../components/FormTextArea';
import { mutation, query } from '../api/config';
import { motion } from 'framer-motion';
import { FormSelect } from '../components/FormSelect';

const Profile = z.object({
    firstName: z.string().min(3),
    lastName: z.string().min(3),
    website: z.string().min(3),
    email: z.string().email(),
    phoneCode: z.string().min(2),
    phone: z.string().min(10),
    businessType: z.array(z.string()),
    business: z.string().min(1),
    industry: z.string().min(1),
    jobFunction: z.string().min(3),
    message: z.string().min(3)
});

type ProfileType = z.infer<typeof Profile>;

export const GetStartedModal = () => {
    const [profile, setProfile] = useState<ProfileType>({} as ProfileType);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [keyId, setKeyId] = useState(v4());
    const { mutate } = mutation({
        url: 'https://script.google.com/macros/s/AKfycbzqDxwXW3oXYqBdHp83GqnlyE87Z36fM8tiRAPfrZ9d76sNk6Mt6SSmy4PZMb0bESOm8Q/exec'
    });
    const { isLoading: isBusinessTypesLoading, data: businessTypes } = query<
        { name: string; id: string }[]
    >({
        url: '/business/type',
        cacheKey: 'businessTypes'
    });
    const { isLoading: isBusinessIndustriesLoading, data: businessIndustries } =
        query<{ name: string; id: string }[]>({
            url: '/business/industry',
            cacheKey: 'businessIndustries'
        });

    useEffect(() => {
        const validate = Profile.safeParse(profile);

        setIsSubmitEnabled(validate.success);
    });

    const onHandleSubmit = () => {
        const validate = Profile.safeParse(profile);

        if (!validate.success) {
            setErrors({});

            const { issues } = validate.error;
            for (const { message, path } of issues) {
                const [field] = path;
                setErrors((error) => ({
                    ...error,
                    [field]: message
                }));
            }

            return;
        }

        const form = new FormData();

        for (const [key, value] of Object.entries(profile)) {
            form.append(key, Array.isArray(value) ? value.join() : value);
        }

        mutate(form, {
            onSettled: (_, error) => {
                if (error) {
                    toast.error('Request failed. Please try again later');
                    return;
                }

                setProfile({} as ProfileType);
                toast.info('Request submitted successfully');
            }
        });

        setKeyId(v4());
    };

    return (
        <motion.section
            initial={{ opacity: 0, x: 30 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className=' bg-white text-ebony p-[42px] xl:min-h-[100%] lg:px-[48px] lg:py-[76px] text-left'
        >
            <div className='font-semibold text-[28px] lg:text-[38px] leading-tight '>
                Get started with FrontEdge
            </div>
            <div className='mb-5 lg:mb-10 lg:text-[18px]'>
                Complete this form, and our team will get in touch with you
                shortly.
            </div>
            <div>
                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-4'>
                    <FormInput
                        key={`${keyId}-a`}
                        label={'First Name'}
                        placeholder={'Enter your first name'}
                        isError={!!errors['firstName']}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                firstName: text
                            }));
                        }}
                    />
                    <FormInput
                        key={`${keyId}-b`}
                        label={'Last Name'}
                        placeholder={'Enter your last name'}
                        isError={!!errors['lastName']}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                lastName: text
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-4'>
                    <FormInput
                        key={`${keyId}-c`}
                        label={'Business Name'}
                        isError={!!errors['business']}
                        placeholder={'FrontEdge Inc.'}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                business: text
                            }));
                        }}
                    />
                    <FormInput
                        key={`${keyId}-d`}
                        label={'Company Website'}
                        isError={!!errors['website']}
                        placeholder={'www.company.com'}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                website: text
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-4'>
                    <FormInput
                        key={`${keyId}-e`}
                        label={'Work Email'}
                        placeholder={'jane@who.com'}
                        isError={!!errors['email']}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                email: text
                            }));
                        }}
                    />
                    <FormPhoneSelect
                        key={`${keyId}-f`}
                        label={'Phone Number'}
                        placeholder={'Phone'}
                        isError={!!errors['phoneCode'] || !!errors['phone']}
                        onHandlePhoneCodeChange={(entry: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                phoneCode: entry
                            }));
                        }}
                        onHandlePhoneChange={(entry: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                phone: entry
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-4'>
                    <FormSelect
                        key={`${keyId}-g`}
                        label={'Industry'}
                        isError={!!errors['industry']}
                        isLoading={isBusinessIndustriesLoading}
                        data={businessIndustries?.payload || []}
                        onHandleChange={(text) => {
                            setProfile((prev) => ({
                                ...prev,
                                industry: text.toString()
                            }));
                        }}
                    />

                    <FormSelect
                        key={`${keyId}-h`}
                        label={'Business Type'}
                        isError={!!errors['businessType']}
                        multi={true}
                        isLoading={isBusinessTypesLoading}
                        data={businessTypes?.payload || []}
                        onHandleChange={(entries) => {
                            setProfile((prev) => ({
                                ...prev,
                                businessType: [
                                    ...new Set([
                                        ...(prev.businessType || []),
                                        ...entries
                                    ])
                                ].map((item) => item.toString())
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1'>
                    <FormInput
                        key={`${keyId}-i`}
                        label={'Job Function'}
                        placeholder={'Select job function'}
                        isError={!!errors['jobFunction']}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                jobFunction: text
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1'>
                    <FormTextArea
                        key={`${keyId}-j`}
                        placeholder={'Add extra'}
                        label={'Message'}
                        isError={!!errors['message']}
                        onHandleChange={(text: string) => {
                            setProfile((prev) => ({
                                ...prev,
                                message: text
                            }));
                        }}
                    />
                </div>
                <div className='grid grid-cols-1'>
                    <Button
                        {...{
                            styles: 'text-white bg-ebony w-full lg:w-full',
                            text: 'send message',
                            onHandleClick: onHandleSubmit,
                            disabled: !isSubmitEnabled
                        }}
                    />
                </div>
            </div>
        </motion.section>
    );
};
