import { FooterCopyright } from '../components/FooterCopyright';
import { FooterInfo } from '../components/FooterInfo';
import { FooterSocials } from '../components/FooterSocials';

export const Footer = () => {
    return (
        <section className='mb-[20px] lg:m-[100px]'>
            <FooterInfo />
            <div className='lg:flex lg:justify-between lg:items-center'>
                <FooterCopyright />
                <FooterSocials />
            </div>
        </section>
    );
};
