import { InstagramIcon } from '../shared/InstagramIcon';
import { LinkedInIcon } from '../shared/LinkedInIcon';

export const FooterSocials = () => {
    return (
        <div className='flex space-x-2 mx-[30px] lg:mx-0'>
            <a
                href='https://instagram.com/frontedge.io'
                target='_blank'
                className='h-[22px] w-[22px]'
            >
                <InstagramIcon />
            </a>
            <a
                href='https://ng.linkedin.com/company/front-edge'
                target='_blank'
                className='h-[22px] w-[22px]'
            >
                <LinkedInIcon />
            </a>
        </div>
    );
};
