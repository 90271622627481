export const PlusIcon = () => {
    return (
        <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ display: 'inline', marginTop: '4px', marginLeft: 'auto' }}
        >
            <circle cx='11' cy='11' r='11' fill='white' />
            <path
                d='M11.3143 8.79999V11.3143M11.3143 11.3143V13.8286M11.3143 11.3143H13.8286M11.3143 11.3143L8.79999 11.3143'
                stroke='#181C39'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
