import React, { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Home } from './Home';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false
        }
    }
});
const theme = createTheme({
    typography: {
        fontFamily: 'Outfit, sans-serif'
    }
});
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <StrictMode>
                    <Home />
                </StrictMode>
            </QueryClientProvider>
        </BrowserRouter>
    </ThemeProvider>
);
