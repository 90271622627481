import { PartnerSlider } from '../components/PartnerSlider';

const partnerList = [
    new URL('../assets/partners/Flexport.svg', import.meta.url).toString(),
    new URL('../assets/partners/JPMorgan.svg', import.meta.url).toString(),
    new URL('../assets/partners/NEPC.svg', import.meta.url).toString(),
    new URL('../assets/partners/Providus.svg', import.meta.url).toString()
];

export const TrustedPartners = () => {
    return (
        <section className='text-white pb-[40px] lg:pb-[112px] m-[20px] lg:mx-[100px] mt-2 text-center space-y-2 lg:space-y-0 lg:flex lg:items-center lg:justify-between'>
            <div className='text-[18px] lg:text-[24px] lg:pt-[30px] lg:text-left'>
                Our trusted partners:
            </div>
            <div className='lg:w-[70%]'>
                <PartnerSlider list={partnerList} />
            </div>
        </section>
    );
};
