import { useState } from 'react';
import { Button } from '../components/Button';
import { GetStarted } from '../components/GetStarted';

export const CallToAction = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <section className='p-[20px] lg:mx-[100px] h-[500px] lg:h-[900px] bg-[url(./assets/img/Hero.svg)] bg-cover bg-[center_top_85px] bg-no-repeat lg:bg-contain lg:bg-[center_top_100px]'>
            <div className='flex flex-col lg:flex-row lg:space-x-8 lg:space-y-0 lg:justify-center items-center'>
                <GetStarted
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
                <Button
                    {...{
                        styles: 'border-[1px] border-white text-white mt-3 lg:mt-0',
                        text: 'learn more',
                        onHandleClick: () => {
                            location.href = '#products';
                        }
                    }}
                />
            </div>
        </section>
    );
};
