export const OffshoreIcon = () => {
    return (
        <svg
            width='65'
            height='65'
            viewBox='0 0 65 65'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_3_147)'>
                <path
                    d='M24.048 37.1719H32.823C35.9247 37.1719 38.9187 38.0717 41.4802 39.7719C41.68 39.9097 41.9053 40.0063 42.1429 40.0562C42.3804 40.1061 42.6255 40.1082 42.8639 40.0622C43.1022 40.0164 43.3291 39.9234 43.5312 39.7889C43.7333 39.6544 43.9066 39.481 44.0409 39.2788C44.1752 39.0765 44.268 38.8497 44.3137 38.6113C44.3595 38.3728 44.3572 38.1277 44.3071 37.8902C44.2571 37.6526 44.1603 37.4274 44.0223 37.2277C43.8841 37.028 43.7078 36.8578 43.5033 36.727C41.8313 35.6253 39.9972 34.792 38.0677 34.257C40.4746 32.6671 42.45 30.5053 43.8171 27.9652C45.1843 25.4251 45.9007 22.5857 45.9022 19.7011C45.9022 10.0709 38.0677 2.23438 28.4355 2.23438C18.8033 2.23438 10.9688 10.0709 10.9688 19.7011C10.9707 22.5862 11.6875 25.4259 13.055 27.9662C14.4225 30.5067 16.3982 32.6687 18.8053 34.259C10.6966 36.5523 4.73081 44.0009 4.73081 52.8328V55.3312C4.73081 58.0795 6.67268 60.4419 9.33971 60.9436C15.5492 62.1522 21.9741 62.7636 28.4355 62.7636H28.598C29.6075 62.7636 30.3448 61.945 30.3448 60.9355C30.3448 59.9259 29.445 59.1073 28.4355 59.1073C22.2077 59.1073 16.0164 58.5162 10.0263 57.3523C9.56078 57.2588 9.14253 57.0056 8.84366 56.6367C8.54478 56.2678 8.38402 55.806 8.38909 55.3312V52.8328C8.38909 44.1959 15.4131 37.1719 24.048 37.1719ZM14.627 19.7011C14.627 12.0859 20.8203 5.89063 28.4355 5.89063C36.0506 5.89063 42.2439 12.0859 42.2439 19.7011C42.2439 27.3162 36.0506 33.5115 28.4355 33.5115C20.8203 33.5115 14.627 27.3162 14.627 19.7011Z'
                    fill='#02BAFF'
                />
                <path
                    d='M55.6948 43.5297H38.0514C36.8387 43.5313 35.6761 44.0138 34.8187 44.8713C33.9611 45.7288 33.4787 46.8914 33.477 48.1041V58.1953C33.477 60.7161 35.5286 62.7656 38.0473 62.7656H55.6948C56.9076 62.764 58.0701 62.2816 58.9277 61.4241C59.7851 60.5665 60.2677 59.404 60.2692 58.1913V48.1041C60.2677 46.8914 59.7851 45.7288 58.9277 44.8713C58.0701 44.0138 56.9076 43.5313 55.6948 43.5297ZM38.0514 47.1859H55.6948C56.2006 47.1859 56.6109 47.5983 56.6109 48.1041V49.6519H37.1353V48.1041C37.1353 47.5983 37.5456 47.1859 38.0514 47.1859ZM55.6948 59.1094H38.0473C37.8054 59.1089 37.5733 59.0123 37.4023 58.841C37.2314 58.6696 37.1353 58.4374 37.1353 58.1953V53.3081H56.6109V58.1913C56.6109 58.4344 56.5146 58.6676 56.3428 58.8397C56.171 59.0119 55.9381 59.1089 55.6948 59.1094Z'
                    fill='#02BAFF'
                />
            </g>
            <defs>
                <clipPath id='clip0_3_147'>
                    <rect width='65' height='65' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
