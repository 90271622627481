import { motion } from 'framer-motion';
import { useState } from 'react';
import { GetStarted } from '../components/GetStarted';

export const JumpStart = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <section className='m-[20px] py-[50px] px-[70px] lg:py-[145px] lg:mx-[100px] lg:flex lg:flex-col lg:items-center text-white rounded-xl text-center bg-radial-gradient'>
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='mb-[40px] leading-[50px] lg:leading-[75px] font-semibold text-[38px] lg:text-[60px] lg:w-[762px]'
            >
                Accelerate your trade growth with us
            </motion.div>
            <GetStarted isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </section>
    );
};
