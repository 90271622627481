import { motion } from 'framer-motion';
import { MenuLinks } from './MenuLinks';
import { MenuProps } from '../interfaces/Menu';

const variants = {
    open: { height: 'auto' },
    closed: { height: 0 }
};

export const HamburgerMenuItems = (props: MenuProps) => {
    const { isOpen, setIsOpen } = props;
    const signInUrl = process.env.REACT_DASHBOARD_URL;

    return (
        <motion.div
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            className='mt-3 absolute mx-[30px] w-[85%] left-0 shadow-lg overflow-hidden bg-white text-ebony'
        >
            <ul
                onMouseUp={() => setIsOpen(false)}
                className='space-y-4 capitalize p-[20px]'
            >
                <MenuLinks />
                <li className='capitalize'>
                    <a
                        href={signInUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        sign in
                    </a>
                </li>
            </ul>
        </motion.div>
    );
};
