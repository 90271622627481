import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

const http = axios.create({
    baseURL: process.env.REACT_BASE_URL
});

interface QueryOptions {
    url: string;
    cacheKey?: string;
    params?: Record<string, string | number>;
    enabled?: boolean;
}

interface MutateOptions {
    url: string;
}

export const query = <T>(options: QueryOptions) => {
    const { url, cacheKey } = options;

    return useQuery({
        queryFn: async (): Promise<{ status: number; payload: T }> => {
            const { status, data: result } = await http.get<
                string,
                { status: number; data: { data: T } }
            >(url);
            const { data: payload } = result;

            return { status, payload };
        },
        queryKey: [cacheKey]
    });
};

export const mutation = (options: MutateOptions) => {
    const { url } = options;

    return useMutation({
        mutationFn: async (body: unknown): Promise<{ status: number }> => {
            const response = await http.post(url, body, {
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8'
                }
            });
            const { status } = response;

            return { status };
        }
    });
};
