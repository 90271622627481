export const MenuLinks = () => {
    return (
        <>
            <li>
                <a href='#mission'>mission</a>
            </li>
            <li>
                <a href='#products'>products</a>
            </li>
            <li>
                <a href='#impact'>impact</a>
            </li>
            <li className='uppercase'>
                <a href='#faq'>faq</a>
            </li>
        </>
    );
};
