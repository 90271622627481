import { MinusIcon } from '../shared/MinusIcon';
import { PlusIcon } from '../shared/PlusIcon';

interface AccordionOpenCloseIndicatorProps {
    isOpen: boolean;
}

export const AccordionOpenCloseIndicator = (
    props: AccordionOpenCloseIndicatorProps
) => {
    const { isOpen } = props;

    return <>{isOpen ? <MinusIcon /> : <PlusIcon />}</>;
};
