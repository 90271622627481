export const CancelIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M30.5916 8.93284C29.6977 8.03899 28.2484 8.03899 27.3545 8.93284L19.262 17.0254L11.1694 8.93284C10.2755 8.03899 8.82621 8.03899 7.93236 8.93284C7.0385 9.8267 7.0385 11.276 7.93236 12.1699L16.0249 20.2624L7.93536 28.352C7.0415 29.2459 7.0415 30.6952 7.93536 31.5891C8.82922 32.4829 10.2785 32.4829 11.1724 31.5891L19.262 23.4995L27.3539 31.5914C28.2477 32.4853 29.6971 32.4853 30.5909 31.5914C31.4848 30.6976 31.4848 29.2482 30.5909 28.3544L22.499 20.2624L30.5916 12.1699C31.4854 11.276 31.4854 9.82681 30.5916 8.93284Z'
                fill='#06070E'
            />
        </svg>
    );
};
