import {
    Autocomplete,
    Box,
    Divider,
    FormControl,
    TextField
} from '@mui/material';
import { query } from '../api/config';

export const FormPhoneSelect = ({
    label,
    placeholder,
    isError,
    onHandlePhoneCodeChange,
    onHandlePhoneChange
}: {
    label: string;
    placeholder?: string;
    isError?: boolean;
    onHandlePhoneCodeChange: (entry: string) => void;
    onHandlePhoneChange: (entry: string) => void;
}) => {
    const { isLoading, data } = query<
        { name: string; phoneCode: string; flagImageUrl: string }[]
    >({
        url: '/countries',
        cacheKey: 'countries'
    });

    return (
        <div className='mb-2 lg:mb-5'>
            <label
                className='font-semibold inline-block mb-[10px]'
                htmlFor={label}
            >
                {label}
            </label>
            <TextField
                id={label}
                placeholder={placeholder}
                className='bg-athensGray border lg:p-[14px] border-portGore border-opacity-10 w-full rounded-md'
                variant='outlined'
                disabled={isLoading}
                error={isError}
                onChange={(e) => {
                    onHandlePhoneChange(e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <>
                            <FormControl>
                                <Autocomplete
                                    sx={{
                                        '& .MuiAutocomplete-input': {
                                            p: '0 !important',
                                            width: '100px !important'
                                        },
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                                            {
                                                border: 0,
                                                p: 0
                                            }
                                    }}
                                    componentsProps={{
                                        paper: {
                                            sx: {
                                                width: 200
                                            }
                                        }
                                    }}
                                    onChange={(_, value) => {
                                        onHandlePhoneCodeChange(
                                            value.phoneCode
                                        );
                                    }}
                                    disableClearable
                                    autoSelect={true}
                                    getOptionLabel={(option) =>
                                        option.phoneCode
                                    }
                                    disabled={isLoading}
                                    options={data ? data.payload : []}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                                outline: 'none'
                                            }}
                                            {...params}
                                        />
                                    )}
                                    renderOption={(
                                        props,
                                        { phoneCode, name, flagImageUrl }
                                    ) => (
                                        <Box
                                            sx={{
                                                outline: 'none',
                                                p: 2
                                            }}
                                            component='li'
                                            {...props}
                                            key={phoneCode}
                                        >
                                            <img
                                                src={flagImageUrl}
                                                alt={`Flag of ${name}`}
                                                className='w-5 mr-3'
                                            />{' '}
                                            {name}
                                        </Box>
                                    )}
                                />
                            </FormControl>
                            <Divider
                                sx={{ height: 28, m: 0.5, mr: 2 }}
                                orientation='vertical'
                            />
                        </>
                    )
                }}
            />
        </div>
    );
};
