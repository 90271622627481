import { CallToAction } from './sections/CallToAction';
import { FAQ } from './sections/FAQ';
import { Footer } from './sections/Footer';
import { Headings } from './sections/Headings';
import { Impact } from './sections/Impact';
import { JumpStart } from './sections/JumpStart';
import { Menu } from './sections/Menu';
import { Mission } from './sections/Mission';
import { Products } from './sections/Products';
import { TrustedPartners } from './sections/TrustedPartners';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';


export const Home = () => {
    return (
        <>
            <ToastContainer toastClassName='toast-z-index' />
            <div className='bg-radial-gradient'>
                <Menu />
                <Headings />
                <CallToAction />
                <TrustedPartners />
            </div>
            <Mission />
            <Products />
            <Impact />
            <FAQ />
            <JumpStart />
            <Footer />
        </>
    );
};
