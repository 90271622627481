export const InstagramIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.4833 3.33331H28.1833C33.7833 3.33331 38.3333 7.88331 38.3333 13.4833V28.1833C38.3333 30.8753 37.2639 33.457 35.3604 35.3604C33.457 37.2639 30.8753 38.3333 28.1833 38.3333H13.4833C7.88331 38.3333 3.33331 33.7833 3.33331 28.1833V13.4833C3.33331 10.7914 4.40269 8.20967 6.30618 6.30618C8.20967 4.40269 10.7914 3.33331 13.4833 3.33331ZM13.1333 6.83331C11.4624 6.83331 9.86002 7.49706 8.67854 8.67854C7.49706 9.86002 6.83331 11.4624 6.83331 13.1333V28.5333C6.83331 32.0158 9.65081 34.8333 13.1333 34.8333H28.5333C30.2042 34.8333 31.8066 34.1696 32.9881 32.9881C34.1696 31.8066 34.8333 30.2042 34.8333 28.5333V13.1333C34.8333 9.65081 32.0158 6.83331 28.5333 6.83331H13.1333ZM30.0208 9.45831C30.601 9.45831 31.1574 9.68878 31.5676 10.099C31.9778 10.5093 32.2083 11.0657 32.2083 11.6458C32.2083 12.226 31.9778 12.7824 31.5676 13.1926C31.1574 13.6028 30.601 13.8333 30.0208 13.8333C29.4407 13.8333 28.8843 13.6028 28.474 13.1926C28.0638 12.7824 27.8333 12.226 27.8333 11.6458C27.8333 11.0657 28.0638 10.5093 28.474 10.099C28.8843 9.68878 29.4407 9.45831 30.0208 9.45831ZM20.8333 12.0833C23.154 12.0833 25.3796 13.0052 27.0205 14.6461C28.6614 16.2871 29.5833 18.5127 29.5833 20.8333C29.5833 23.154 28.6614 25.3796 27.0205 27.0205C25.3796 28.6614 23.154 29.5833 20.8333 29.5833C18.5127 29.5833 16.2871 28.6614 14.6461 27.0205C13.0052 25.3796 12.0833 23.154 12.0833 20.8333C12.0833 18.5127 13.0052 16.2871 14.6461 14.6461C16.2871 13.0052 18.5127 12.0833 20.8333 12.0833ZM20.8333 15.5833C19.4409 15.5833 18.1056 16.1364 17.121 17.121C16.1364 18.1056 15.5833 19.4409 15.5833 20.8333C15.5833 22.2257 16.1364 23.5611 17.121 24.5456C18.1056 25.5302 19.4409 26.0833 20.8333 26.0833C22.2257 26.0833 23.5611 25.5302 24.5456 24.5456C25.5302 23.5611 26.0833 22.2257 26.0833 20.8333C26.0833 19.4409 25.5302 18.1056 24.5456 17.121C23.5611 16.1364 22.2257 15.5833 20.8333 15.5833Z'
                fill='#181C39'
            />
        </svg>
    );
};
