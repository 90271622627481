import { Autocomplete, Box, FormControl, TextField } from '@mui/material';

export const FormSelect = <T extends { id: string; name: string }[]>({
    label,
    isError,
    onHandleChange,
    multi,
    isLoading,
    data
}: {
    label: string;
    isError?: boolean;
    multi?: boolean;
    onHandleChange: (entry: string | string[]) => void;
    isLoading: boolean;
    data: T;
}) => {
    return (
        <div className='mb-2 lg:mb-5'>
            <label className='font-semibold block mb-[10px]' htmlFor={label}>
                {label}
            </label>

            <FormControl className='w-full'>
                <Autocomplete
                    multiple={multi}
                    className='bg-athensGray border border-portGore border-opacity-30 p-[6px] rounded-md'
                    sx={{
                        '& .MuiAutocomplete-input': {
                            p: '0 !important',
                            width: '50px !important'
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                            {
                                border: 0,
                                p: 0
                            }
                    }}
                    onChange={(_, value) => {
                        const v = Array.isArray(value)
                            ? value.map(({ id }) => id)
                            : value?.id;
                        onHandleChange(v);
                    }}
                    disableClearable
                    autoSelect={true}
                    getOptionLabel={(option) => option.name}
                    disabled={isLoading}
                    options={data}
                    renderInput={(params) => (
                        <TextField
                            sx={{ outline: 'none' }}
                            {...params}
                            variant='outlined'
                            error={isError}
                        />
                    )}
                    renderOption={(props, { name }) => (
                        <Box
                            sx={{ outline: 'none', p: 2 }}
                            component='li'
                            {...props}
                            key={name}
                        >
                            {name}
                        </Box>
                    )}
                />
            </FormControl>
        </div>
    );
};
