import { motion } from 'framer-motion';
import { MissionCheckMarkMobile } from '../shared/MissionCheckMarkMobile';

const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
};

const item = {
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.3
        }
    },
    hidden: {
        opacity: 0,
        y: 20,
        transition: {
            when: 'afterChildren'
        }
    }
};

export const MissionTron = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className='h-[417px] md:w-[336px] md:mx-auto lg:w-[615] lg:h-[642px] rounded-xl text-left bg-[url(./assets/img/Mission.svg)] bg-cover bg-center'
        >
            <motion.ul
                initial='hidden'
                animate='visible'
                variants={list}
                className='relative inline-block w-full ml-[20px] lg:ml-[40px]'
            >
                <div className='absolute top-[280] lg:top-[400]'>
                    {[
                        'Accessible to businesses across Africa',
                        'Easily scale and reach your trade potential',
                        'Products to meet your needs'
                    ].map((text) => (
                        <motion.li
                            variants={item}
                            key={text}
                            className='text-[12px] lg:text-[18px] mb-5'
                        >
                            <span className='text-ebony bg-white py-2 px-3 rounded-full'>
                                {<MissionCheckMarkMobile />} {text}
                            </span>
                        </motion.li>
                    ))}
                </div>
            </motion.ul>
        </motion.div>
    );
};
