import { MenuProps } from '../interfaces/Menu';

const line = 'block h-0.5 w-6 transition ease transform duration-300 bg-white';

export const HamburgerMenu = (props: MenuProps) => {
    const { isOpen, setIsOpen } = props;

    return (
        <button onClick={() => setIsOpen(!isOpen)} className='space-y-1 mt-3'>
            <span
                className={`${line} ${isOpen && 'rotate-45 translate-y-1'}`}
            ></span>
            <span className={`${line} ${isOpen && 'opacity-0'}`}></span>
            <span
                className={`${line} ${isOpen && '-rotate-45 -translate-y-2'}`}
            ></span>
        </button>
    );
};
