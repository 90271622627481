import { MissionStatement } from '../components/MissionStatement';
import { MissionTron } from '../components/MissionTron';

export const Mission = () => {
    return (
        <section
            id='mission'
            className='mx-[20px] my-[20px] lg:m-[100px] lg:flex lg:flex-row-reverse lg:justify-between'
        >
            <MissionTron />
            <MissionStatement />
        </section>
    );
};
