import { motion } from 'framer-motion';
import { useState } from 'react';
import { GetStarted } from './GetStarted';

export const MissionStatement = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className='text-center mt-10 md:px-[100px] lg:px-[20px] lg:text-left lg:w-[500px]'>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='uppercase text-dodgerBlue mb-3 lg:mb-4'
            >
                mission
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-semibold text-[40px] lg:text-[60px] leading-tight mb-3 lg:mb-4'
            >
                Unlock your trade potential
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-light text-comet text-[20px] leading-9 lg:pb-[20px] mb-3 lg:mb-4'
            >
                FrontEdge enables the growth of African cross-border trade by
                providing exporters and importers with the capital and tools to
                scale and reach their potential.
            </motion.div>
            <GetStarted
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                inverted={true}
            />
        </div>
    );
};
