import { motion } from 'framer-motion';

export const ImpactTron = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className='h-[277px] lg:w-[45%] md:w-[580px] md:h-[726px] rounded-xl text-left bg-[url(./assets/img/Impact.svg)] bg-cover bg-center overflow-hidden'
        ></motion.div>
    );
};
