export const MinusIcon = () => {
    return (
        <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ display: 'inline', marginTop: '4px', marginLeft: 'auto' }}
        >
            <circle cx='11' cy='11' r='11' fill='#02BAFF' />
            <path
                d='M13.8286 11.3143H8.79999'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
