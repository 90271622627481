import { motion } from 'framer-motion';

export const Headings = () => {
    return (
        <section className='p-[20px] mt-[50px] lg:mt-[100px] text-center space-y-2 text-white'>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-extrabold text-[46px] leading-tight lg:text-[72px] lg:mx-[200px]'
            >
                The Growth Partner for African Exporters and Importers
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='text-[22px] lg:text-[24px]'
            >
                Seamlessly access capital and tools for international trade.
            </motion.div>
        </section>
    );
};
