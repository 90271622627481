import { Dispatch, SetStateAction } from 'react';
import { Button } from './Button';
import { Modal } from './Modal';
import { GetStartedModal } from '../sections/GetStartedModal';

export const GetStarted = ({
    isOpen,
    setIsModalOpen,
    inverted
}: {
    isOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    inverted?: boolean;
}) => {
    return (
        <>
            <Button
                {...{
                    styles: inverted
                        ? 'text-white bg-ebony'
                        : 'text-ebony bg-white',
                    text: 'get started',
                    onHandleClick: () => {
                        setIsModalOpen(!isOpen);
                    }
                }}
            />
            <Modal isOpen={isOpen} setIsModalOpen={setIsModalOpen}>
                <GetStartedModal />
            </Modal>
        </>
    );
};
