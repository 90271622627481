import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react';
import { CancelIcon } from '../shared/CancelIcon';

export const Modal = ({
    children,
    isOpen,
    setIsModalOpen
}: PropsWithChildren & {
    isOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    });

    return (
        <>
            {isOpen && (
                <div className='flex z-50 flex-row-reverse fixed m-0 p-0 top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50 overflow-scroll space-x-0 md:space-x-0 lg:space-x-0'>
                    <div
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        className='absolute w-[24px] h-[24px] right-10 top-5 cursor-pointer'
                    >
                        <CancelIcon />
                    </div>
                    <div className='w-full lg:w-[50%]'>{children}</div>
                </div>
            )}
        </>
    );
};
