import { motion } from 'framer-motion';
import { ProductItems } from '../components/ProductItems';

export const Products = () => {
    return (
        <section
            id='products'
            className='mt-[60px] pb-[50px] lg:px-[100px] lg:pt-[80px] lg:pb-[100px] text-center bg-athensGray'
        >
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='uppercase pt-[20px] text-dodgerBlue'
            >
                products
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-semibold pb-[40px] lg:pb-[80px] text-[35px] lg:text-[60px] leading-tight mt-[25px]'
            >
                Access all the tools you require
            </motion.div>
            <ProductItems />
        </section>
    );
};
