export const LinkedInIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M36.1111 3C37.1425 3 38.1317 3.40972 38.861 4.13903C39.5903 4.86834 40 5.85749 40 6.88889V34.1111C40 35.1425 39.5903 36.1317 38.861 36.861C38.1317 37.5903 37.1425 38 36.1111 38H8.88889C7.85749 38 6.86834 37.5903 6.13903 36.861C5.40972 36.1317 5 35.1425 5 34.1111V6.88889C5 5.85749 5.40972 4.86834 6.13903 4.13903C6.86834 3.40972 7.85749 3 8.88889 3H36.1111ZM35.1389 33.1389V22.8333C35.1389 21.1522 34.471 19.5398 33.2823 18.3511C32.0935 17.1623 30.4812 16.4944 28.8 16.4944C27.1472 16.4944 25.2222 17.5056 24.2889 19.0222V16.8639H18.8639V33.1389H24.2889V23.5528C24.2889 22.0556 25.4944 20.8306 26.9917 20.8306C27.7136 20.8306 28.4061 21.1174 28.9166 21.6279C29.4271 22.1384 29.7139 22.8308 29.7139 23.5528V33.1389H35.1389ZM12.5444 13.8111C13.4108 13.8111 14.2417 13.4669 14.8543 12.8543C15.4669 12.2417 15.8111 11.4108 15.8111 10.5444C15.8111 8.73611 14.3528 7.25833 12.5444 7.25833C11.6729 7.25833 10.8371 7.60455 10.2208 8.22081C9.60455 8.83708 9.25833 9.67291 9.25833 10.5444C9.25833 12.3528 10.7361 13.8111 12.5444 13.8111ZM15.2472 33.1389V16.8639H9.86111V33.1389H15.2472Z'
                fill='#181C39'
            />
        </svg>
    );
};
