import { useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

const clones = 30;

export const PartnerSlider = ({ list }: { list: string[] }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const matches = useMediaQuery('(min-width:800px)');
    const [containerWidth, setContainerWidth] = useState(0);
    const [speedStep, setSpeedStep] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.clientWidth);
        }
    });

    useEffect(() => {
        setSpeedStep(matches ? 70 : 40);
    });

    return (
        <div
            ref={containerRef}
            className='overflow-hidden mt-10 before:top-0 before:w-20 before:h-full before:content-none before:z-[1] before:left-0 before:bg-gradient-to-r after:top-0 after:w-20 after:h-full after:content-none after:z-[1] after:right-0 after:bg-gradient-to-l'
        >
            <motion.div
                initial={{ x: 0 }}
                animate={{ x: -((containerWidth / 5) * list.length * clones) }}
                transition={{
                    duration: list.length * speedStep,
                    ease: 'linear',
                    repeat: Infinity
                }}
                className='flex space-x-20'
            >
                {Array.from(Array(clones).keys()).map(() =>
                    list.map((url, idx) => <img key={`${idx}_`} src={url} />)
                )}
            </motion.div>
        </div>
    );
};
