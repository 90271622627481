import { TextField } from '@mui/material';

export const FormInput = ({
    label,
    placeholder,
    isError,
    onHandleChange
}: {
    label: string;
    placeholder?: string;
    isError?: boolean;
    onHandleChange: (text: string) => void;
}) => {
    return (
        <div className='mb-2 lg:mb-5'>
            {label && (
                <label
                    className='font-semibold inline-block mb-[10px]'
                    htmlFor={label}
                >
                    {label}
                </label>
            )}
            <TextField
                id={label}
                error={isError}
                placeholder={placeholder}
                className='bg-athensGray border lg:p-[14px] border-portGore border-opacity-10 w-full rounded-md'
                variant='outlined'
                onChange={(e) => onHandleChange(e.target.value)}
            />
        </div>
    );
};
