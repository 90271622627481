import { TextField } from '@mui/material';

export const FormTextArea = ({
    label,
    placeholder,
    onHandleChange,
    isError
}: {
    label: string;
    placeholder?: string;
    onHandleChange: (text: string) => void;
    isError?: boolean;
}) => {
    return (
        <div className='mb-5'>
            <label
                className='font-semibold inline-block mb-[10px]'
                htmlFor={label}
            >
                {label}
            </label>
            <TextField
                id={label}
                rows={2}
                multiline
                placeholder={placeholder}
                error={isError}
                onChange={(e) => onHandleChange(e.target.value)}
                className='bg-athensGray border lg:p-[19px] border-portGore border-opacity-10 w-full rounded-md resize-none'
            />
        </div>
    );
};
