export const WalletIcon = () => {
    return (
        <svg
            width='65'
            height='65'
            viewBox='0 0 65 65'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_3_123)'>
                <path
                    d='M53.8281 5.68549H11.1719C8.80233 5.68818 6.5306 6.63066 4.85508 8.30619C3.17955 9.98172 2.23707 12.2534 2.23438 14.623V41.9636C2.23438 46.8914 6.24406 50.9011 11.1719 50.9011H34.2062C34.6911 50.9011 35.1561 50.7085 35.4989 50.3656C35.8418 50.0229 36.0344 49.5578 36.0344 49.073C36.0344 48.5882 35.8418 48.1231 35.4989 47.7804C35.1561 47.4375 34.6911 47.2449 34.2062 47.2449H11.1719C9.7717 47.2432 8.42932 46.6863 7.43925 45.6963C6.44917 44.7062 5.89224 43.3638 5.89063 41.9636V27.7855H59.1094V33.8589C59.1094 34.3438 59.3019 34.8088 59.6449 35.1516C59.9876 35.4945 60.4527 35.6871 60.9375 35.6871C61.4223 35.6871 61.8874 35.4945 62.2301 35.1516C62.573 34.8088 62.7656 34.3438 62.7656 33.8589V14.623C62.7656 9.69517 58.7559 5.68549 53.8281 5.68549ZM11.1719 9.34174H53.8281C56.7409 9.34174 59.1094 11.7122 59.1094 14.623V15.8357H5.89063V14.623C5.89063 11.7122 8.25906 9.34174 11.1719 9.34174ZM5.89063 24.1292V19.4919H59.1094V24.1313H5.89063V24.1292Z'
                    fill='#02BAFF'
                />
                <path
                    d='M12.8111 35.2767H18.4112C18.8961 35.2767 19.3611 35.0841 19.704 34.7412C20.0468 34.3985 20.2394 33.9334 20.2394 33.4486C20.2394 32.9638 20.0468 32.4987 19.704 32.156C19.3611 31.8131 18.8961 31.6205 18.4112 31.6205H12.8111C12.3262 31.6205 11.8613 31.8131 11.5184 32.156C11.1756 32.4987 10.983 32.9638 10.983 33.4486C10.983 33.9334 11.1756 34.3985 11.5184 34.7412C11.8613 35.0841 12.3262 35.2767 12.8111 35.2767ZM12.8111 42.1586H24.0114C24.4962 42.1586 24.9612 41.966 25.3041 41.6231C25.6469 41.2803 25.8395 40.8153 25.8395 40.3305C25.8395 39.8456 25.6469 39.3806 25.3041 39.0378C24.9612 38.6949 24.4962 38.5024 24.0114 38.5024H12.8111C12.3262 38.5024 11.8613 38.6949 11.5184 39.0378C11.1756 39.3806 10.983 39.8456 10.983 40.3305C10.983 40.8153 11.1756 41.2803 11.5184 41.6231C11.8613 41.966 12.3262 42.1586 12.8111 42.1586Z'
                    fill='#02BAFF'
                />
                <path
                    d='M42.8411 39.39C40.6372 41.5939 39.4225 44.525 39.4225 47.643C39.4225 50.7609 40.6372 53.692 42.8411 55.8959C45.047 58.0999 47.9761 59.3145 51.094 59.3145C54.212 59.3145 57.1431 58.0999 59.347 55.8959C61.5312 53.7051 62.7577 50.7376 62.7577 47.644C62.7577 44.5504 61.5312 41.5829 59.347 39.392C57.1572 37.2054 54.1892 35.9771 51.0946 35.9767C47.9998 35.9764 45.0315 37.204 42.8411 39.39ZM56.7612 53.3102C53.7347 56.3367 48.4534 56.3388 45.4269 53.3102C43.9115 51.7969 43.0787 49.7839 43.0787 47.643C43.0787 45.502 43.9115 43.4891 45.4269 41.9738C46.9889 40.4117 49.0405 39.6297 51.094 39.6297C53.1476 39.6297 55.1992 40.4117 56.7612 41.9738C58.2618 43.4785 59.1044 45.5169 59.1044 47.642C59.1044 49.767 58.2618 51.8054 56.7612 53.3102Z'
                    fill='#02BAFF'
                />
                <path
                    d='M49.725 51.5897C49.448 51.5899 49.1745 51.5271 48.9254 51.4061C48.6762 51.285 48.4579 51.1089 48.2869 50.8909L46.5136 48.6301C46.2376 48.247 46.1204 47.7719 46.1866 47.3044C46.2527 46.8368 46.497 46.4129 46.8683 46.1212C47.2398 45.8296 47.7095 45.6927 48.1793 45.7393C48.6493 45.786 49.083 46.0125 49.3898 46.3714L50.0216 47.1758L53.0887 44.7037C53.4664 44.3997 53.9494 44.258 54.4314 44.3099C54.9136 44.362 55.3552 44.6033 55.6593 44.981C55.9635 45.3587 56.105 45.8416 56.0531 46.3237C56.0011 46.8058 55.7597 47.2475 55.382 47.5515L50.8727 51.1855C50.5474 51.4471 50.1424 51.5898 49.725 51.5897Z'
                    fill='#02BAFF'
                />
            </g>
            <defs>
                <clipPath id='clip0_3_123'>
                    <rect width='65' height='65' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
