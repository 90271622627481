import { BlackLogo } from './Logo';
import {
    WhatsApp as WhatsAppIcon,
    MailOutline as EmailIcon
} from '@mui/icons-material';

const locations = [
    {
        section: 'Company',
        entries: [
            <a href='#mission'>Mission</a>,
            <a href='#products'>Products</a>,
            <a href='#impact'>Impact</a>,
            <a href='#faq'>FAQ</a>
        ]
    },
    {
        section: 'Nigeria',
        entries: ['2 Onikoyi Rd', 'Ikoyi', 'Lagos']
    },
    {
        section: 'USA',
        entries: ['38 E 50th St', 'New York', 'NY 10022']
    },
    {
        section: 'Contact',
        entries: [
            <>
                <WhatsAppIcon /> {'+234 704 700 0406'}
            </>,
            <>
                <EmailIcon /> {'sales@frontedge.io'}
            </>
        ]
    }
];

export const FooterInfo = () => {
    return (
        <div className='px-[30px] lg:p-0 mt-[60px] border-b-[1px] border-black lg:flex lg:justify-between'>
            <div className='lg:w-[305px]'>
                <div className='w-[150px]'>
                    <BlackLogo />
                </div>
                <div className='text-ebony mt-[20px] leading-7 lg:text-[20px]'>
                    Africa's growth partner for international trade.
                </div>
            </div>
            <div className='mt-[30px] md:mt-[0] pb-[50px] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-[160px] lg:gap-[60px]'>
                {locations.map(({ section, entries }) => (
                    <ul key={section} className='mb-[20px]'>
                        <span className='inline-block mb-[20px] md:text-[18px]'>
                            {section}
                        </span>
                        {entries.map((entry, index) => (
                            <li key={index} className='mb-[10px] text-comet'>
                                {entry}
                            </li>
                        ))}
                    </ul>
                ))}
            </div>
        </div>
    );
};
