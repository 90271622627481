import { motion } from 'framer-motion';
import { CargoIcon } from '../shared/CargoIcon';
import { DocumentIcon } from '../shared/DocumentIcon';
import { LogisticsIcon } from '../shared/LogisticsIcon';
import { OffshoreIcon } from '../shared/OffshoreIcon';
import { PaymentIcon } from '../shared/PaymentIcon';
import { WalletIcon } from '../shared/WalletIcon';

const items = [
    {
        icon: <WalletIcon />,
        title: 'Financing',
        content:
            'Get paid upfront on all your shipments. Ship your goods and pay us later.'
    },
    {
        icon: <PaymentIcon />,
        title: 'Cross-border payments',
        content: 'Exchange currencies and send payments instantly.'
    },
    {
        icon: <OffshoreIcon />,
        title: 'Offshore Accounts',
        content:
            'Create multi-currency accounts and make international transactions like a local.'
    },
    {
        icon: <CargoIcon />,
        title: 'Cargo Insurance',
        content: 'Insure your cargo the entirety of its journey.'
    },
    {
        icon: <LogisticsIcon />,
        title: 'Logistics Management',
        content:
            'Manage your logistics with us and get faster release on your cargo.'
    },
    {
        icon: <DocumentIcon />,
        title: 'Document Management',
        content:
            'Create, manage and collaborate on all documents you need for your shipment.'
    }
];

export const ProductItems = () => {
    return (
        <div className='text-left md:px-[40px] md:grid md:gap-3 md:grid-cols-2 lg:grid-cols-3'>
            {items.map(({ icon, title, content }) => (
                <motion.div
                    key={title}
                    initial={{ y: 50, opacity: 0.5 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 0.5,
                        delay: 0.2,
                        ease: 'easeInOut'
                    }}
                    viewport={{ once: true }}
                    className='bg-white mx-[20px] md:mx-0 py-[30px] px-[20px] md:p-[40px] border-2 mb-[25px] space-y-5 rounded-lg'
                >
                    <div>{icon}</div>
                    <div className='font-semibold text-[20px] md:text-[24px]'>
                        {title}
                    </div>
                    <div className='font-light text-comet text-[16px] md:text-[18px]'>
                        {content}
                    </div>
                </motion.div>
            ))}
        </div>
    );
};
