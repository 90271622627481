interface ButtonProps {
    styles?: string;
    onHandleClick?: () => void;
    text: string;
    disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
    const { disabled, styles, text, onHandleClick } = props;

    return (
        <button
            disabled={disabled}
            onClick={onHandleClick}
            className={`px-[20px] capitalize h-[48px] lg:w-[176px] lg:h-[64px] rounded-md cursor-pointer ${
                disabled ? 'opacity-70' : ''
            } ${styles ? styles : ''}`}
        >
            {text}
        </button>
    );
};
