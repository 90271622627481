import { useState } from 'react';
import { DesktopMenu } from '../components/DesktopMenu';
import { WhiteLogo } from '../components/Logo';
import { MobileMenu } from '../components/MobileMenu';
import { GetStarted } from '../components/GetStarted';
import { Button } from '../components/Button';


export const Menu = () => {
    const signInUrl = process.env.REACT_DASHBOARD_URL;
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <nav className='flex items-center justify-between px-[60px] py-[24px] text-white'>
            <a href='/'>
                <WhiteLogo />
            </a>
            <div className='lg:hidden'>
                <MobileMenu />
            </div>
            <div className='hidden lg:inline-block'>
                <DesktopMenu />
            </div>
            <div className='hidden lg:inline-block'>
                <Button
                    {...{
                        styles: 'text-white mt-3 md:mt-0 mr-2',
                        text: 'sign in',
                        onHandleClick: () => {
                            window.open(signInUrl, '_blank')?.focus();
                        }
                    }}
                />

                <GetStarted
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            </div>
        </nav>
    );
};
