import { motion } from 'framer-motion';
import { Accordion } from '../components/Accordion';

const faqs = [
    {
        title: 'Who are we?',
        content:
            'FrontEdge is a fintech focused on enabling the growth of African cross-border trade. We provide exporters and importers with working capital and software tools to facilitate their cross-border transactions.'
    },
    {
        title: 'What are the costs and requirements to sign up?',
        content:
            'Signing up with FrontEdge is quick and easy, and absolutely free. However, we only serve incorporated businesses and not individuals. To sign up, we simply need your contact details and KYC documents related to your business. Our onboarding process takes 24-48 hours.'
    },
    {
        title: 'How come I can get a free offshore account?',
        content: `We are committed to helping you manage your international transactions seamlessly. That's why we subsidize offshore accounts. These accounts empower you to make international transactions like a local, avoiding costly conversion fees and ensuring you have control over your cross-border finances.`
    },
    {
        title: 'What costs and speeds should I expect for payments?',
        content:
            'At FrontEdge, we understand the importance of efficient and affordable payments in international trade. Our platform provides the most competitive rates for currency exchange and international transfers. As for speed, as we have connections to global rails, our payments are processed with the utmost efficiency ensuring that your payments reach their destination in a timely manner, keeping your business operations on track.'
    },
    {
        title: 'What payment licenses does FrontEdge have?',
        content:
            'We hold all the necessary payment licences required for our operations. Our Payment and FX product is licensed with PSSP and FCA regulated partners in the UK and Nigeria. Our commitment to adhering to industry regulations and licensing requirements reflects our dedication to providing a safe and trusted platform for international trade.'
    },
    {
        title: 'How secure is FrontEdge?',
        content:
            'Security is at the core of our platform. We employ advanced security measures to protect your data and financial transactions. Our systems are designed with robust encryption, multi-factor authentication, and regular security audits to safeguard your information. Your trust is our priority, and we continuously invest in cutting-edge technology to maintain the highest security standards.'
    }
];

export const FAQ = () => {
    return (
        <section
            id='faq'
            className='m-[20px] lg:mx-[100px] text-center mt-[80px] space-y-5 lg:mb-[80px]'
        >
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='uppercase text-dodgerBlue'
            >
                faq
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-semibold text-[35px] lg:text-[60px] leading-tight'
            >
                Questions & Answers
            </motion.div>
            <motion.div
                variants={{
                    initial: { y: 20, opacity: 0 },
                    final: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            delayChildren: 0.5
                        }
                    }
                }}
                initial='initial'
                whileInView='final'
                transition={{ duration: 0.5, delay: 0.6, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='text-left p-[20px] lg:p-[70px] bg-athensGray rounded-lg'
            >
                {faqs.map(({ title, content }) => (
                    <Accordion
                        key={title}
                        {...{
                            title,
                            content
                        }}
                    />
                ))}
            </motion.div>
        </section>
    );
};
