import { motion } from 'framer-motion';
import { GetStarted } from './GetStarted';
import { useState } from 'react';

export const ImpactStatement = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className='space-y-4 lg:w-[45%] overflow-hidden'>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='uppercase text-dodgerBlue'
            >
                impact
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='font-semibold text-[35px] lg:text-[50px] leading-tight'
            >
                Sustainability
            </motion.div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='text-[20px] lg:text-[24px] leading-8 lg:leading-9 pb-[30px] border-b-[1px] border-black text-comet'
            >
                FrontEdge is committed to facilitating trade in sustainably
                produced goods of the highest quality from reputable traders
                across Africa with a focus on delivering social dividends.
            </motion.div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-2 px-1 mt-[20px]'>
                <img
                    src={new URL(
                        '../assets/img/SDG2.svg',
                        import.meta.url
                    ).toString()}
                />
                <img
                    src={new URL(
                        '../assets/img/SDG5.svg',
                        import.meta.url
                    ).toString()}
                />
                <img
                    src={new URL(
                        '../assets/img/SDG8.svg',
                        import.meta.url
                    ).toString()}
                />
                <img
                    src={new URL(
                        '../assets/img/SDG13.svg',
                        import.meta.url
                    ).toString()}
                />
            </div>
            <div>
                <GetStarted
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    inverted={true}
                />
            </div>
        </div>
    );
};
