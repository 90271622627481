import { useState } from 'react';
import { AccordionOpenCloseIndicator } from './AccordionOpenCloseIndicator';
import { motion } from 'framer-motion';

interface AccordionProps {
    title: string;
    content: string;
}

const variants = {
    open: { height: 'auto' },
    closed: { height: 0 }
};

export const Accordion = (props: AccordionProps) => {
    const { title, content } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <motion.div
            variants={{
                initial: { y: 20, opacity: 0 },
                final: {
                    y: 0,
                    opacity: 1
                }
            }}
            animate='final'
            className='border-b-[2px] border-ebony-500 pb-[20px] mb-[20px]'
        >
            <button
                className={`flex justify-between text-left w-full font-semibold text-[20px] ${
                    isOpen ? 'pb-[20px]' : ''
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
                <div className='w-[22px] h-[22px]'>
                    <AccordionOpenCloseIndicator isOpen={isOpen} />
                </div>
            </button>
            <motion.div
                animate={isOpen ? 'open' : 'closed'}
                variants={variants}
                className='overflow-hidden text-comet text-[18px]'
            >
                {content}
            </motion.div>
        </motion.div>
    );
};
