import { useState } from 'react';
import { HamburgerMenu } from './HamburgerMenu';
import { HamburgerMenuItems } from './HamburgerMenuItems';

export const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <HamburgerMenu {...{ isOpen, setIsOpen }} />
            <HamburgerMenuItems {...{ isOpen, setIsOpen }} />
        </>
    );
};
