export const CargoIcon = () => {
    return (
        <svg
            width='65'
            height='65'
            viewBox='0 0 65 65'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_3_159)'>
                <path
                    d='M38.218 19.1872V7.92189H46.7187C49.0709 7.92189 50.9844 9.83533 50.9844 12.1875V29.5953C50.9844 30.0801 51.1769 30.5452 51.5199 30.888C51.8626 31.2309 52.3277 31.4234 52.8125 31.4234C53.2973 31.4234 53.7624 31.2309 54.1051 30.888C54.448 30.5452 54.6406 30.0801 54.6406 29.5953V12.1875C54.6406 7.82033 51.0859 4.26564 46.7187 4.26564H10.1562C5.78906 4.26564 2.23438 7.82033 2.23438 12.1875V48.75C2.23438 53.1172 5.78906 56.6719 10.1562 56.6719H35.7703C36.2551 56.6719 36.7202 56.4793 37.0629 56.1364C37.4058 55.7936 37.5984 55.3286 37.5984 54.8438C37.5984 54.3589 37.4058 53.8939 37.0629 53.5511C36.7202 53.2082 36.2551 53.0156 35.7703 53.0156H10.1562C7.80406 53.0156 5.89063 51.1022 5.89063 48.75V12.1875C5.89063 9.83533 7.80203 7.92189 10.1562 7.92189H18.657V19.1872C18.657 20.8 19.9692 22.1122 21.582 22.1122H35.293C36.9058 22.1122 38.218 20.8 38.218 19.1872ZM22.3133 18.4559V7.92189H34.5597V18.458L22.3133 18.4559Z'
                    fill='#02BAFF'
                />
                <path
                    d='M12.7685 44.3097C12.2836 44.3097 11.8187 44.5023 11.4758 44.8452C11.133 45.188 10.9404 45.653 10.9404 46.1378C10.9404 46.6227 11.133 47.0877 11.4758 47.4306C11.8187 47.7734 12.2836 47.9659 12.7685 47.9659H20.3227C20.8075 47.9659 21.2725 47.7734 21.6154 47.4306C21.9582 47.0877 22.1508 46.6227 22.1508 46.1378C22.1508 45.653 21.9582 45.188 21.6154 44.8452C21.2725 44.5023 20.8075 44.3097 20.3227 44.3097H12.7685Z'
                    fill='#02BAFF'
                />
                <path
                    d='M60.6917 39.2356L52.2153 34.9781C51.6911 34.7151 51.1127 34.5781 50.5263 34.5781C49.9398 34.5781 49.3615 34.7151 48.8373 34.9781L40.363 39.2356C39.7288 39.5498 39.1971 40.0378 38.83 40.6428C38.4627 41.2478 38.2752 41.9447 38.289 42.6522C38.3358 45.9164 39.2701 56.8567 49.2131 60.5028C50.0608 60.8111 50.9898 60.8111 51.8375 60.5028C61.7805 56.8567 62.7128 45.9164 62.7615 42.6522C62.777 41.9448 62.5905 41.2477 62.2239 40.6426C61.8573 40.0375 61.3258 39.5495 60.6917 39.2356ZM50.5822 57.072H50.4704C42.9304 54.3055 41.9961 46.0281 42.0042 42.5039L50.574 38.2464L59.1094 42.5994C59.0565 46.0281 58.1222 54.3034 50.5822 57.072Z'
                    fill='#02BAFF'
                />
            </g>
            <defs>
                <clipPath id='clip0_3_159'>
                    <rect width='65' height='65' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
