const blackLogoUrl = new URL(
    '../assets/img/BlackLogo.svg',
    import.meta.url
).toString();

const whiteLogoUrl = new URL(
    '../assets/img/WhiteLogo.svg',
    import.meta.url
).toString();

export const BlackLogo = () => {
    return <img src={blackLogoUrl} alt='FrontEdge Logo' />;
};

export const WhiteLogo = () => {
    return <img src={whiteLogoUrl} alt='FrontEdge Logo' />;
};
