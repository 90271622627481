import { MenuLinks } from './MenuLinks';

export const DesktopMenu = () => {
    return (
        <>
            <ul className='flex space-x-8 capitalize text-[18px] ml-[50px]'>
                <MenuLinks />
            </ul>
        </>
    );
};
