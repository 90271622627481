import { ImpactStatement } from '../components/ImpactStatement';
import { ImpactTron } from '../components/ImpactTron';

export const Impact = () => {
    return (
        <section id='impact' className='bg-white mt-[40px]'>
            <div className='w-[70%] space-y-8 lg:mx-[100px] lg:w-[90%] mx-auto pt-[60px] lg:px-[64px] lg:flex lg:flex-row-reverse lg:justify-between'>
                <ImpactTron />
                <ImpactStatement />
            </div>
        </section>
    );
};
